@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

@font-face {
  font-family: 'MonaSans';
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/monasans/MonaSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'MonaSans';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/monasans/MonaSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'MonaSans';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/monasans/MonaSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MonaSans';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/monasans/MonaSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MonaSans';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/monasans/MonaSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'MonaSans';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/monasans/MonaSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MonaSans';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/monasans/MonaSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/satoshi/Satoshi-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-ExtraBold.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  border: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: var.$white;
}

::-webkit-scrollbar-thumb {
  background-color: var.$gray;
  border-radius: 5px;
  border: 2px solid var.$white;
}

html,
body,
#root {
  height: 100%;
  font-weight: 400;
  color: var.$black;
  background-color: var.$gray;
  font-family: var.$manrope;

  // &:lang(ru) {
  //   font-family: var.$manrope;
  // }

  // &:lang(en) {
  //   font-family: var.$monasans;
  // }
}

textarea {
  font-family: var.$manrope;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type='date'] {
  width: 100%;
  min-width: 100%;
  min-height: 46px;
  height: 46px;
  box-sizing: border-box;
  text-align: left;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.custom-slide {
  width: fit-content !important;
  height: auto !important;
  box-sizing: border-box;
}

.custom-banner-slide {
  width: fit-content !important;
  height: auto !important;
  box-sizing: border-box;

  @include mix.vp-1439 {
    width: 100% !important;
  }
}

.show {
  animation: show 0.3s ease-in-out;
}

.hide {
  animation: hide 0.3s ease-in-out;
}

.modal-opened {
  overflow: hidden;
  padding-right: 10px;

  @include mix.vp-1024 {
    padding-right: 0;
  }
}
